<template>
  <vx-card :title="title">
    <vs-tabs :value="active">
      <vs-tab label="Territory">
        <Territory></Territory>
      </vs-tab>
      <vs-tab label="Territory Area">
        <TerritoryArea></TerritoryArea>
      </vs-tab>
      <vs-tab label="Zones">
        <Zones></Zones>
      </vs-tab>
      <vs-tab label="Operating Unit">
        <OperatingUnit></OperatingUnit>
      </vs-tab>
      <vs-tab label="Operating Unit Entity">
        <OperatingUnitEntity></OperatingUnitEntity>
      </vs-tab>
      <vs-tab label="Operating Unit Bank Account">
        <OperatingUnitBankAccount></OperatingUnitBankAccount>
      </vs-tab>
      <vs-tab label="Operating Unit Branch Operation Bank">
        <OperatingUnitBranchOperationBank></OperatingUnitBranchOperationBank>
      </vs-tab>
      <vs-tab label="Operating Unit Petty Cash">
        <OperatingUnitPettyCash></OperatingUnitPettyCash>
      </vs-tab>
      <vs-tab label="Vehicle">
        <Vehicle></Vehicle>
      </vs-tab>
      <vs-tab label="Vehicle Class">
        <VehicleClass></VehicleClass>
      </vs-tab>
      <vs-tab label="Warehouse">
        <Warehouse></Warehouse>
      </vs-tab>
      <vs-tab label="Warehouse Area">
        <WarehouseArea></WarehouseArea>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Territory from "./territory-begbal/index.vue";
import TerritoryArea from "./territory-area-begbal/index.vue";
import Zones from "./zones-begbal/index.vue";
import OperatingUnit from "./operating-unit/index.vue";
import OperatingUnitBankAccount from "./operating-unit-bank-account/index.vue";
import OperatingUnitEntity from "./operating-unit-entity/index.vue";
import Vehicle from "./vehicle/index.vue"
import VehicleClass from "./vehicle-class/index.vue"
import Warehouse from "./Warehouse/index.vue"
import WarehouseArea from "./Warehouse-area/index.vue"
import OperatingUnitBranchOperationBank from "./operating-unit-branch-operation-bank/index.vue"
import OperatingUnitPettyCash from "./operating-unit-petty-cash/index.vue"

export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    Territory,
    TerritoryArea,
    Zones,
    OperatingUnit,
    OperatingUnitEntity,
    OperatingUnitBankAccount,
    Vehicle,
    VehicleClass,
    Warehouse,
    WarehouseArea,
    OperatingUnitBranchOperationBank,
    OperatingUnitPettyCash
  },
  data() {
    return {
      title: "Organization Structure",
    };
  },
};
</script>
